<template>
  <div class="project-about ">
    <div class="left">
      <h2 class="title">{{ data.title }}</h2>
      <div class="description" v-html="data.teaser"></div>
    </div>
    <div class="right">
      <h2 class="title">{{ data.title2 }}</h2>
      <span class="description" v-html="data.teaser2"></span>
      <div class="image-container">
        <video v-if="data.videoUrl" id="vid"
               data-scroll
               data-scroll-speed="-1"
               autoplay
               loop
               muted
               :playsinline="true">
          <source :src="data.videoUrl" type="video/mp4">
          <source :src="data.videoUrl" type="video/ogg">
          Your browser does not support the video tag.
        </video>
        <img v-else :src="data.image" alt="">
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted} from "vue";

let props = defineProps({
  contentData: {
    type: Object
  }
})

let data = computed(() => {
  let item = props.contentData?.data?.list?.[0] || {}

  return {
    ...item,
    videoUrl: item.video?.videoFile?.url,
    image: item.image?.[0]?.devices?.desktop
  }
})

onMounted(() => {
  document.getElementById('vid')?.play();
})

</script>

<style lang="scss" scoped>
.project-about {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  color: #000000;
  padding: 144px 120px 0;

  @media screen and(max-width: 1900px) {
    padding: 99px 100px 0;
  }

  @media screen and(max-width: 1439px) {
    padding: 81px 80px 0;
  }

  @media screen and(max-width: 1279px) {
    padding: 48px 48px 0;
  }

  @media screen and(max-width: 767px) {
    padding: 0 16px 0;
    margin-top: 34px;
    display: block;
  }

  .title {
    line-height: unset;
  }

  span {
    display: block;
  }

  .left {
    width: 40%;
    min-width: 549px;
    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      min-width: 450px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      min-width: 322px;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      min-width: unset;
    }

    .title {
      font-size: 64px;
      @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        font-size: 24px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 28px;
      }
    }

    .description {
      font-size: 21px;
      line-height: 30px;
      margin-top: 55px;

      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        font-size: 16px;
        line-height: 19px;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 34px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .right {
    width: 480px;
    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
      width: 400px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
      width: 270px;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-top: 34px;
    }

    .title {
      font-size: 48px;
      @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        font-size: 34px;
      }
      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 24px;
      }
    }

    .description {
      font-size: 24px;
      margin-top: 15px;
      @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        font-size: 21px;
      }
      @media only screen and (max-width: 1023px) and (min-width: 768px) {
        font-size: 14px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 21px;
      }
    }

    .image-container {
      position: relative;
      margin-top: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      clip-path: polygon(0% 21.6%, 33.7% 21.6%, 33.7% 0%, 69.3% 0%, 69.3% 8.3%, 100% 8.3%, 100% 100%, 13% 100%, 13% 77.94%, 0% 77.94%);

      video {
        height: 100%;
        width: 100%;
        z-index: 1;
        position: absolute;
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>